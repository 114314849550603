import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';

import { connectorStatusSelector } from '../../store/selectors/connector.selectors';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { FlexRow } from '../../styled/flex/FlexRow';
import { theme } from '../../theme';
import { ConnectorStatus } from '../../types/connector.types';
import { ConnectorTag } from '../../types/tag.types';
import { getConnectorStatusIconListBorderColor } from '../../utils/connectors/getConnectorStatusIconListBorderColor';
import { AlarmIcon } from '../icons/general/status/AlarmIcon';
import { WarningIcon } from '../icons/general/status/WarningIcon';
import { ConnectorStartConditionsTooltip } from './ConnectorStartConditionsTooltip';
import { ConnectorStatusWithNumber } from './ConnectorStatusWithNumber';
import { IconByStatusValue } from './IconByStatusValue';
import { ActiveEnergyConsumptionIcon } from './icons/connectorsTable/electricity/ActiveEnergyConsumptionIcon';
import { NoPowerIcon } from './icons/connectorsTable/electricity/NoPowerIcon';
import { AuthorisationForcedIcon } from './icons/connectorsTable/general/authorisation/AuthorisationForcedIcon';
import { AuthorisationSuccessIcon } from './icons/connectorsTable/general/authorisation/AuthorisationSuccessIcon';
import { NoAuthorisationIcon } from './icons/connectorsTable/general/authorisation/NoAuthorisationIcon';
import { PilotContactOfflineIcon } from './icons/connectorsTable/general/pilotContact/PilotContactOfflineIcon';
import { PilotContactOnlineIcon } from './icons/connectorsTable/general/pilotContact/PilotContactOnlineIcon';
import { NoStartConditionsIcon } from './icons/connectorsTable/general/startConditions/NoStartConditionsIcon';
import { StartConditionsFailedIcon } from './icons/connectorsTable/general/startConditions/StartConditionsFailedIcon';
import { StartConditionsSuccessIcon } from './icons/connectorsTable/general/startConditions/StartConditionsSuccessIcon';
import { ActiveWaterConsumptionIcon } from './icons/connectorsTable/water/ActiveWaterConsumptionIcon';
import { NoWaterIcon } from './icons/connectorsTable/water/NoWaterIcon';

interface IProps {
  boxId: string;
  connectorId: string;
  connectorIndex: number;
  connectorWebStatusValue?: string;
  connectorSymbolStartValue?: string;
  connector?: any;
}

interface IStyledProps {
  connectorStatus: ConnectorStatus;
}

const StyledFlexRow = styled(FlexRow)(({ connectorStatus }: IStyledProps) => ({
  border: `1px solid ${getConnectorStatusIconListBorderColor(connectorStatus)}`,
  alignItems: 'end',
  padding: theme.spacing.xxSmall,
  borderRadius: theme.borderRadius.xxxLarge,
  width: 'fit-content',
}));

const StyledDivider = styled('div')(({ connectorStatus }: IStyledProps) => ({
  borderLeft: `1.5px solid ${getConnectorStatusIconListBorderColor(
    connectorStatus,
  )}`,
  height: '32px',
  marginRight: theme.spacing.xSmall,
}));

const pilotContactContentList: any = {
  0: null,
  1: <PilotContactOnlineIcon />,
  2: <PilotContactOfflineIcon />,
};

const authorisationContentList: any = {
  0: <NoAuthorisationIcon />,
  1: <AuthorisationSuccessIcon />,
  2: <AuthorisationForcedIcon />,
};

const startConditionsContentList: any = {
  0: <NoStartConditionsIcon />,
  1: <StartConditionsSuccessIcon />,
  2: <StartConditionsFailedIcon />,
};

const pointAlarmContentList: any = {
  0: null,
  2: <AlarmIcon />,
};

const pointWarningContentList: any = {
  0: null,
  2: <WarningIcon />,
};

const symbolStartContentList: any = {
  0: null,
  1: <NoPowerIcon />,
  2: <ActiveEnergyConsumptionIcon />,
  3: <NoWaterIcon />,
  4: <ActiveWaterConsumptionIcon />,
};

export const ConnectorStatusComponentsList = ({
  boxId,
  connector,
  connectorId,
  connectorIndex,
  connectorSymbolStartValue,
  connectorWebStatusValue,
}: IProps) => {
  const connectorStatus = useSelector(
    connectorStatusSelector(boxId, connectorId),
  );

  const getIcon = (obj: any, tag: any) => { 
    return obj[connector.tagStatuses.find((status: any) => status.tag === tag)?.value || 0];
  }
  

  return (
    <ConnectorStartConditionsTooltip boxId={boxId} connectorId={connectorId}>
      <StyledFlexRow connectorStatus={connectorStatus}>
        <ConnectorStatusWithNumber
          connectorIndex={connectorIndex}
          connectorSymbolStartValue={connectorSymbolStartValue}
          connectorWebStatusValue={connectorWebStatusValue}
        />
        <StyledDivider connectorStatus={connectorStatus} />
        <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
          {getIcon(pilotContactContentList, ConnectorTag.V2_SYMBOL_PILOT)}
        </StyledBox>
        <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
          {getIcon(authorisationContentList, ConnectorTag.V2_SYMBOL_AUTORISATION)}
        </StyledBox>
        <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
          {getIcon(startConditionsContentList, ConnectorTag.V6_START_CONDITION)}
        </StyledBox>
        <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
          {getIcon(symbolStartContentList, ConnectorTag.V2_SYMBOL_START)}
        </StyledBox>
        {connectorStatus === ConnectorStatus.ALARM && (
          <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
            {getIcon(pointAlarmContentList, ConnectorTag.V4_POINT_ALARM)}
          </StyledBox>
        )}
        {connectorStatus === ConnectorStatus.WARNING && (
          <StyledBox mx={theme.spacing.xSmall} mt={theme.spacing.xxSmall}>
            {getIcon(pointWarningContentList, ConnectorTag.V3_POINT_WARNING)}
          </StyledBox>
        )}
      </StyledFlexRow>
    </ConnectorStartConditionsTooltip>
  );
};
