import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IConnectorStatusWarningIconProps } from '../../../../types/connector.types';
import { IconSize } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const WarningIcon = ({
  assets,
  ...space
}: IConnectorStatusWarningIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(assets ? IconSize.ALARM_ICON : IconSize.MEDIUM).width}
      height={
        getIconSize(assets ? IconSize.ALARM_ICON : IconSize.MEDIUM).height
      }
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2330_38772)">
        <path
          d="M0.400024 18.4001L10 1.77246L19.6 18.4001H0.400024Z"
          stroke={colors.yellow.primary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4883 14.7832C10.5747 14.7832 10.6311 14.8408 10.6311 14.9268V15.974C10.6311 16.06 10.5743 16.1176 10.4883 16.1176H9.51274C9.42674 16.1176 9.36914 16.0604 9.36914 15.974V14.9268C9.36914 14.8408 9.42714 14.7832 9.51274 14.7832H10.4883ZM10.4591 7.56201C10.5451 7.56201 10.6023 7.61961 10.6023 7.70561V13.4904C10.6023 13.5764 10.5451 13.634 10.4591 13.634H9.54114C9.45474 13.634 9.39754 13.5764 9.39754 13.4904V7.70561C9.39754 7.61961 9.45514 7.56201 9.54114 7.56201H10.4591Z"
          fill={colors.yellow.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_38772">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
