/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';

export default createIcon(
  <>
    <path d="M1.26 18.969a.36.36 0 01-.341-.474l2.722-8.166H.9a.36.36 0 01-.333-.498l3.6-8.64A.36.36 0 014.5.969h3.42a.36.36 0 01.329.507L5.416 7.809H8.1a.361.361 0 01.301.557l-6.84 10.44a.36.36 0 01-.3.163z" />
  </>,
  '0 0 9 19',
  'LightningBolt',
);
