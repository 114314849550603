import styled from '@emotion/styled';
import React from 'react';

import { StyledBox } from '../../styled/boxes/StyledBox';
import { FlexBox } from '../../styled/flex/FlexBox';
import { theme } from '../../theme';
import { getConnectorStatusIconAndBackgroundColor } from '../../utils/connectors/getConnectorStatusIconAndBackgroundColor';
import { ConnectorStatusComponent } from './ConnectorStatusComponent';

interface IProps {
  active?: boolean;
  connectorIndex: number;
  connectorSymbolStartValue?: string;
  connectorWebStatusValue?: string;
}

interface IStyledProps {
  active?: boolean;
  connectorWebStatusValue?: string;
}

const StyledFlexBox = styled(FlexBox)(
  ({ active, connectorWebStatusValue }: IStyledProps) => ({
    alignItems: 'center',
    borderRadius: '50%',
    border: `1px solid ${
      getConnectorStatusIconAndBackgroundColor(active, connectorWebStatusValue)
        .iconBorderColor
    }`,
    color: getConnectorStatusIconAndBackgroundColor(
      active,
      connectorWebStatusValue,
    ).iconColor,
    backgroundColor: getConnectorStatusIconAndBackgroundColor(
      active,
      connectorWebStatusValue,
    ).iconBackgroundColor,
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    marginRight: theme.spacing.xSmall,
  }),
);

export const ConnectorStatusWithNumber = ({
  active,
  connectorIndex,
  connectorSymbolStartValue,
  connectorWebStatusValue,
}: IProps) => {
  return (
    <StyledFlexBox connectorWebStatusValue={connectorWebStatusValue}>
      <FlexBox alignSelf="end">
        <ConnectorStatusComponent
          active={active}
          connectorWebStatusValue={connectorWebStatusValue}
          connectorSymbolStartValue={connectorSymbolStartValue}
        />
      </FlexBox>
      <StyledBox fontSize={theme.fontSizes.medium}>{connectorIndex + 1}</StyledBox>
    </StyledFlexBox>
  );
};
