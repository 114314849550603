import styled from '@emotion/styled';
import { Input } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { colors, theme } from '../../theme';

const { Search } = Input;

export const StyledSearch = styled(Search)<LayoutProps | SpaceProps>(
  {
    width: '150px',
    '.ant-input': {
      borderRadius: theme.borderRadius.medium,
    },
    '.ant-input-group-addon': {
      borderRadius: `${theme.borderRadius.medium}`,
    },
    '.ant-input-search-button': {
      borderTopRightRadius: `${theme.borderRadius.medium} !important`,
      borderBottomRightRadius: `${theme.borderRadius.medium} !important`,
    },

    '.ant-input:hover': {
      border: `1px solid ${colors.green.primary}`,
    },

    '.ant-input:focus, .ant-input-search-button:hover': {
      boxShadow: 'none',
      border: `1px solid ${colors.green.primary}`,
    },

    '.ant-btn:focus': {
      border: `1px solid ${colors.green.primary}`,
    },

    '.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary)':
      {
        borderLeft: 'none',
      },
  },
  layout,
  space,
);
