import styled from '@emotion/styled';
import { Button } from 'antd';
import { layout, LayoutProps, space, SpaceProps, variant } from 'styled-system';

import { colors, theme } from '../../theme';

type Variants = 'primary' | 'secondary' | 'danger' | 'info';

interface IProps {
  variant: Variants;
  fullWidth?: boolean;
  fontWeight?: string;
  height?: string;
}

// Extracting BaseButtonProps from ButtonProps
type BaseButtonProps = React.ComponentProps<typeof Button>;

// Creating a new type merging BaseButtonProps with IProps
type StyledButtonProps = SpaceProps & LayoutProps & IProps & BaseButtonProps;

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ fontWeight, fullWidth, height, ...rest }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: theme.fontSizes.medium,
    width: fullWidth ? '100%' : 'auto', // Conditionally set width based on fullWidth prop
    height: height || 'auto',
    fontWeight: fontWeight || 'auto',
    borderRadius: theme.borderRadius.medium,
  }),
  space,
  layout,
  variant({
    variants: {
      primary: {
        color: colors.white.primary,
        bg: colors.green.primary,
        border: `1px solid ${colors.green.primary}`,
        ':hover': {
          color: colors.green.primary,
          bg: 'transparent',
          borderColor: colors.green.primary,
        },
        ':focus': {
          color: colors.green.primary,
          bg: 'transparent',
          borderColor: colors.green.primary,
        },
      },
      secondary: {
        color: colors.green.primary,
        bg: 'transparent',
        border: `1px solid ${colors.green.primary}`,
        ':hover': {
          color: colors.white.primary,
          bg: colors.green.primary,
          borderColor: colors.green.primary,
        },
        ':focus': {
          color: colors.white.primary,
          bg: colors.green.primary,
          borderColor: colors.green.primary,
        },
      },
      danger: {
        borderRadius: theme.borderRadius.medium,
        color: colors.white.primary,
        bg: colors.red.secondary,
        border: `1px solid ${colors.red.secondary}`,
        ':hover': {
          color: colors.red.secondary,
          bg: 'transparent',
          border: `1px solid ${colors.red.secondary}`,
        },
        ':focus': {
          color: colors.red.secondary,
          bg: 'transparent',
          border: `1px solid ${colors.red.secondary}`,
        },
      },
      info: {
        color: colors.black.primary,
        bg: 'transparent',
        border: `1px solid ${colors.lightGrey.primary}`,
      },
    },
  }),
);
