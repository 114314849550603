import { format } from 'date-fns';
import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../../store/selectors/tags.selectors';
import { BoxTag, ConnectorTag } from '../../../../../types/tag.types';
import { StyledDiv } from '../../../../../styled/StyledDiv';

interface IProps {
  updatedAt: string | number;
  record: any;
  boxId: string;
  tag: BoxTag | ConnectorTag;
}

export const TagsTimestamp = ({ boxId, record, tag, updatedAt }: IProps) => {
  const tagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(boxId, boxId, tag),
  );

  if (record.isTableHeading) return null;

  const timeStamp = format(
    new Date(tagStatus?.updatedAt || updatedAt),
    'dd/MM/yyyy hh:mm',
  );
  const COLUMN_STYLE: CSSProperties = {
    textWrap: 'nowrap',
    textAlign: 'right',
  };
  return (<StyledDiv style={COLUMN_STYLE}>{timeStamp === 'Invalid date' ? null : timeStamp}</StyledDiv>);
};
