import { ExportOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors, theme } from '../../../../theme';
import { BoxActions } from '../../BoxDetail/BoxStatusPortal/BoxActions';
import { Modal } from '../../../../components/modals/Modal';
import { StyledButton } from '../../../../styled/buttons/StyledButton';
import { IAssetInfo } from '../../../../types/box.types';
import { ConnectorTag } from '../../../../types/tag.types';
import { ConnectorStatusWithNumber } from '../../../../components/connectors/ConnectorStatusWithNumber';
import { BoxStatusIconsList } from '../../../../components/boxes/BoxStatusIconsList';
import { appUrls } from '../../../../config/url.constants';
import { SubscribeToTags } from '../SubscribeToTags';
import { RootState } from '../../../../store/store';
import { getBoxConnectivityStatus } from '../../../../utils/boxes/getBoxConnectivityStatus';

interface BoxProps {
  box: IAssetInfo;
}

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xSmall};
  width: 120px;
`;

const StyledBox = styled.div`
  width: 100%;
  min-height: 126px;
  background-color: ${colors.green.tertiary};
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.medium};
  margin-bottom: ${theme.spacing.small};
  cursor: pointer;
`;

const StyledBoxContainer = styled.div`
  padding: ${theme.spacing.medium};

  width: 100%;

  display: flex;
  justify-content: space-between;
`;

const StyledBoxLeft = styled.div<{ boxIsOffline: boolean }>`
  opacity: ${(props) => (props.boxIsOffline ? 0.4 : 1)};
`;

const StyledBoxRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.h3`
  font-weight: ${theme.fontWeights.bolder};
  margin-bottom: 0;
`;

const Location = styled.h4`
  margin-bottom: ${theme.spacing.small};
`;

const Connectors = styled.div``;

export const BoxMobile: React.FC<BoxProps> = ({ box }: BoxProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const boxIsOffline = getBoxConnectivityStatus(box.id);

  const connectorStatuses = useSelector(
    (state: RootState) => state.tags.statuses,
  );

  const renderConnectors = () => {
    const activeConnectors = box.connectors.filter((connector) =>
      connector.tagStatuses.some(
        (ts) => ts.tag === ConnectorTag.V2_POINT_ACTIVE && ts.value === '1',
      ),
    );

    return (
      <FlexContainer>
        {activeConnectors.map((connector) => {
          const connectorType = connector.tagStatuses.find(
            (status) => status.tag === ConnectorTag.V2_SYMBOL_START,
          );

          const statuses =
            connectorStatuses[connector.id] || connector.tagStatuses;
          const updatedStatuses = [...statuses, connectorType];

          const connectorWebStatusValue = updatedStatuses.find(
            (status) => status?.tag === ConnectorTag.V1_WEB_STATUS,
          )?.value;
          const connectorSymbolStartValue = updatedStatuses.find(
            (status) => status?.tag === ConnectorTag.V2_SYMBOL_START,
          )?.value;

          return (
            <>
              <SubscribeToTags connector={connector} />
              <ConnectorStatusWithNumber
                connectorIndex={connector.index}
                connectorWebStatusValue={connectorWebStatusValue}
                connectorSymbolStartValue={connectorSymbolStartValue}
                key={connector.id}
              />
            </>
          );
        })}
      </FlexContainer>
    );
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setModalOpen((prev) => !prev);
  };

  const handleRedirectToBoxDetail = () =>
    navigate(appUrls.assets.statusPortal(box.id));

  return (
    <>
      <StyledBox onClick={handleRedirectToBoxDetail}>
        <StyledBoxContainer>
          <StyledBoxLeft boxIsOffline={boxIsOffline}>
            <Title>{box.label}</Title>
            <Location>Kaai 671-701</Location>
            <Connectors>{renderConnectors()}</Connectors>
          </StyledBoxLeft>

          <StyledBoxRight>
            <BoxStatusIconsList showText={false} boxId={box.id} />

            <StyledButton
              width={theme.input.width.small}
              onClick={handleButtonClick}
              variant="primary"
              data-testid="btn-register-techmetrys-form-submit"
              disabled={boxIsOffline}
            >
              <ExportOutlined />
            </StyledButton>
          </StyledBoxRight>
        </StyledBoxContainer>
      </StyledBox>
      <Modal
        closable
        width={300}
        title="Asset actions"
        showModal={modalOpen}
        onCancel={() => setModalOpen(false)}
      >
        <BoxActions boxId={box.id} />
      </Modal>
    </>
  );
};
