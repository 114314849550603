/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';

export default createIcon(
  <>
    <path d="M21.432.086v8.068h6.216V.086h-6.216zm-9.03 10.4a2.931 2.931 0 00-2.927 2.927h29.427a2.932 2.932 0 00-2.928-2.928H12.402zm-2.927 2.927v9.781L23.38 13.67a1.464 1.464 0 011.654 0l13.868 9.499v-9.755H9.474zm16.177 4.229V54.31h13.973l8.639-21.18-22.612-15.488zm-2.928.026L.151 33.13 8.79 54.31h13.934V17.668z" />
  </>,
  '0 0 49 55',
  'Vessel',
);
