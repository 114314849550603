import { IconSize } from '../../../types/icon.types';

export const getIconSize = (size?: IconSize) => {
  if (size === IconSize.SMALL) {
    return {
      width: '20px',
      height: '20px',
    };
  }
  if (size === IconSize.MEDIUM) {
    return {
      width: '25px',
      height: '25px',
    };
  }
  if (size === IconSize.LARGE) {
    return {
      width: '30px',
      height: '30px',
    };
  }
  if (size === IconSize.XLARGE) {
    return {
      width: '32px',
      height: '33px',
    };
  }
  if (size === IconSize.XXLARGE) {
    return {
      width: '39px',
      height: '39px',
    };
  }
  if (size === IconSize.ALARM_ICON) {
    return {
      width: '34px',
      height: '34px',
    };
  }
  return {
    width: '20px',
    height: '20px',
  };
};
