/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';
import { colors } from '../theme';

export default createIcon(
  <>
    <path
      d="M7.55 11.85L12 16 22 4.2"
      stroke={colors.green.secondary}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M22.182 6.306l-.685.808A10.422 10.422 0 0123 12.5C23 18.29 18.29 23 12.5 23S2 18.29 2 12.5 6.71 2 12.5 2c2.722 0 5.197 1.05 7.064 2.755l.654-.772A11.455 11.455 0 0012.5 1C6.159 1 1 6.159 1 12.5S6.159 24 12.5 24 24 18.841 24 12.5c0-2.28-.67-4.405-1.818-6.194z"
      fill="#63CF97"
    />
  </>,
  '0 0 25 25',
  'Checkmark',
);
