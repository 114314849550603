import React from 'react';
import { RouteObject } from 'react-router-dom';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { appUrls } from '../config/url.constants';
import { BoxesOverview } from '../pages/Boxes/BoxesOverview/BoxesOverview';
import { BoxDetail } from '../pages/Boxes/BoxDetail/BoxDetail';
import { BoxDetailTabs, TaskTabs } from '../types/tab.types';
import { SessionsOverview } from '../pages/Sessions/SessionsOverview/SessionsOverview';
import { SessionDetailOverview } from '../pages/Sessions/SessionDetail/SessionDetailOverview';
import { UsersOverview } from '../pages/Users/UsersOverview/UsersOverview';
import { UserForm } from '../pages/Users/UserForm/UserForm';
import { VesselsOverview } from '../pages/Vessels/VesselsOverview/VesselsOverview';
import { MaintenanceOverview } from '../pages/Maintenance/MaintenanceOverview';
import { TaskEdit } from '../pages/Maintenance/Tasks/TaskEdit';
import { VesselForm } from '../pages/Vessels/VesselForm/VesselForm';
import { ProfilePage } from '../pages/Settings/ProfilePage';
import { LandingsPage } from '../pages/Login/LandingsPage';
import { LoginPage } from '../pages/Login/LoginPage';
import { RegisterCognitoPage } from '../pages/Login/RegisterCognitoPage';
import { ResetPasswordRequestPage } from '../pages/Login/ResetPasswordRequestPage';
import { ResetPasswordSetPage } from '../pages/Login/ResetPasswordSetPage';
import { ResetPasswordCode } from '../pages/Login/ResetPasswordCode';
import { UserPermission } from '../types/user.types';

type ProtectedRoute = RouteObject & {
  permissions?: UserPermission[];
};

export const protectedRoutes: ProtectedRoute[] = [
  {
    path: '/',
    element: <Dashboard />,
    index: true,
    permissions: [],
  },
  {
    element: <Dashboard />,
    path: appUrls.dashboard.base,
    permissions: [],
  },
  {
    path: appUrls.assets.base,
    element: <BoxesOverview />,
    permissions: [UserPermission.MANAGE_ASSETS],
  },
  {
    path: appUrls.assets.statusPortal(),
    element: <BoxDetail activeTab={BoxDetailTabs.STATUS} />,
    permissions: [UserPermission.MANAGE_ASSETS],
  },
  {
    path: appUrls.assets.devPortal(),
    element: <BoxDetail activeTab={BoxDetailTabs.DEVELOPER} />,
    permissions: [UserPermission.MANAGE_ASSETS],
  },
  {
    path: appUrls.assets.sessionsPortal(),
    element: <BoxDetail activeTab={BoxDetailTabs.SESSIONS} />,
    permissions: [UserPermission.MANAGE_ASSETS],
  },
  {
    path: appUrls.assets.logsPortal(),
    element: <BoxDetail activeTab={BoxDetailTabs.LOGS} />,
    permissions: [UserPermission.MANAGE_ASSETS],
  },

  {
    path: appUrls.sessions.base,
    element: <SessionsOverview />,
    permissions: [
      UserPermission.MANAGE_COMPANY_SESSIONS,
      UserPermission.MANAGE_ASSETS,
      UserPermission.CREATE_SESSION,
    ],
  },
  {
    path: appUrls.sessions.detail(),
    element: <SessionDetailOverview />,
    permissions: [
      UserPermission.MANAGE_COMPANY_SESSIONS,
      UserPermission.MANAGE_ASSETS,
      UserPermission.CREATE_SESSION,
    ],
  },
  {
    path: appUrls.users.base,
    element: <UsersOverview />,
    permissions: [UserPermission.MANAGE_USERS],
  },
  {
    path: appUrls.users.new,
    element: <UserForm />,
  },
  {
    path: appUrls.users.edit(),
    element: <UserForm />,
  },
  {
    path: appUrls.users.edit(),
    element: <UserForm />,
  },
  {
    path: appUrls.vessels.base,
    element: <VesselsOverview />,
    permissions: [UserPermission.MANAGE_VESSELS],
  },
  {
    path: appUrls.vessels.new,
    element: <VesselForm />,
    permissions: [UserPermission.MANAGE_VESSELS],
  },
  {
    path: appUrls.vessels.edit(),
    element: <VesselForm />,
    permissions: [UserPermission.MANAGE_VESSELS],
  },
  {
    path: appUrls.maintenance.base,
    element: <MaintenanceOverview />,
    permissions: [],
  },
  {
    path: appUrls.maintenance.edit(),
    element: <TaskEdit activeTab={TaskTabs.EDIT} />,
    permissions: [UserPermission.CREATE_TASK],
  },
  {
    path: appUrls.maintenance.logs(),
    element: <TaskEdit activeTab={TaskTabs.LOGS} />,
    permissions: [UserPermission.CREATE_TASK],
  },
  {
    path: appUrls.settings.profile,
    element: <ProfilePage />,
    permissions: [],
  },
  {
    path: appUrls.login.landingAfterLogin,
    element: <LandingsPage />,
    permissions: [],
  },
  {
    path: '*',
    element: <LandingsPage />,
  },
];

export const publicRoutes: RouteObject[] = [
  /* Public routes */
  {
    path: appUrls.login.base,
    element: <LoginPage />,
  },
  {
    path: appUrls.login.register,
    element: <RegisterCognitoPage />,
  },
  {
    path: appUrls.login.resetPassword,
    element: <ResetPasswordRequestPage />,
  },
  {
    path: appUrls.login.resetPasswordSet(),
    element: <ResetPasswordSetPage />,
  },
  {
    path: appUrls.login.resetPasswordCode,
    element: <ResetPasswordCode />,
  },
  {
    path: '*',
    element: <LoginPage />,
  },
];
