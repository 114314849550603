export const colors = {
  black: {
    primary: '#262626',
    secondary: '#495169',
  },
  blue: {
    primary: '#28B2FF',
    secondary: 'rgba(40, 178, 255, 0.1)',
  },
  brown: {
    primary: '#8D6518',
    secondary: 'rgba(141, 101, 24, 0.1)',
  },
  green: {
    background: '#CCD9D3',
    primary: '#02A24F',
    secondary: '#63CF97',
    tertiary: 'rgba(99, 207, 151, 0.1)',
  },
  grey: {
    primary: '#F2F2F2',
    secondary: '#939BB4',
    tertiary: 'rgba(147, 155, 180, 0.1)',
  },
  lightGrey: {
    primary: '#8C8C8C',
    secondary: '#D9D9D9',
    tertiary: 'rgba(217, 217, 217, 0.1)',
  },
  red: {
    primary: '#FF4D4F',
    secondary: '#CF6363',
    tertiary: 'rgba(207, 99, 99, 0.1)',
  },
  white: {
    primary: '#FFF',
  },
  yellow: {
    primary: '#CFB063',
    secondary: '#FFB80033',
    tertiary: 'rgba(207,176,99,0.1)',
  },
};

export const theme = {
  height: {
    small: '24px',
    medium: '32px',
    large: '48px',
    xLarge: '64px',
  },
  maxWidth: '1200px',
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '10px',
    xLarge: '16px',
    xxLarge: '20px',
    xxxLarge: '30px',
  },
  boxShadows: {
    light: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    green: `2px 4px 4px 0px ${colors.green.primary}`,
  },
  fontFamilies: {
    primary: 'Roboto, sans-serif',
    secondary: 'Libre Franklin, sans-serif',
  },
  fontSizes: {
    xxSmall: 9,
    xSmall: 10,
    small: 13,
    medium: 16,
    large: 20,
    xLarge: 24,
    xxLarge: 30,
    title: 60,
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bolder: 700,
    bold: 900,
  },
  lineHeights: {
    xxSmall: '14px',
    xSmall: '17px',
    small: '20px',
    medium: '25px',
    large: '30px',
    xLarge: '36px',
    xxLarge: '42px',
  },
  spacing: {
    xxxSmall: '2px',
    xxSmall: '4px',
    xSmall: '6px',
    small: '12px',
    medium: '16px',
    large: '20px',
    xLarge: '24px',
    xxLarge: '32px',
    xxxLarge: '48px',
  },
  input: {
    width: {
      small: '45px',
      normal: '150px',
      big: '300px',
      fitContent: 'fit-content',
    },
  },
  breakpoints: {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
    xxl: 1920,
  },
};
