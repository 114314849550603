import styled from '@emotion/styled';
import { Layout, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FOB } from './components/buttons/FOB';
import { MobileNavBar } from './components/navigation/sideBar/MobileNavBar';
import { SideNavBar } from './components/navigation/sideBar/SideNavBar';
import { useGetCurrentUserQuery } from './store/api/users.api';
import { StyledBox } from './styled/boxes/StyledBox';
import { FlexCentered } from './styled/flex/FlexCentered';
import { theme } from './theme';
import { setLayoutFlag } from './store/slices/layout.slice';
import { LayoutStateKeys } from './types/layout.types';
import { getLayoutFlagByKeySelector } from './store/selectors/layout.selectors';
import useResponsive from './hooks/useResponsive';

interface AppLayoutProps {
  children?: React.ReactNode;
}

const { Content } = Layout;

const StyledLayout = styled(Layout)({
  height: '100vh',
  width: '100vw',
});

const StyledContent = styled(Content)({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

export const AppLayout = ({ children }: AppLayoutProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  const { isLoading: isFetchingUser } = useGetCurrentUserQuery();

  const isCollapsed = useSelector(
    getLayoutFlagByKeySelector(LayoutStateKeys.SIDEBAR_TOGGLE_COLLAPSED),
  );

  const handleSidebarClick = () => {
    dispatch(
      setLayoutFlag({
        key: LayoutStateKeys.SIDEBAR_TOGGLE_COLLAPSED,
        flagValue: !isCollapsed,
      }),
    );
  };

  const conditionalMainScreen = !isMobile ? (
    <StyledBox
      p={theme.spacing.large}
      ml={isCollapsed ? '100px' : '220px'}
      width="100%"
    >
      <StyledContent>{children}</StyledContent>
    </StyledBox>
  ) : (
    <StyledBox p={theme.spacing.large} ml={0} width="100%">
      <StyledContent>{children}</StyledContent>
    </StyledBox>
  );

  return (
    <StyledLayout>
      {isMobile ? (
        <MobileNavBar
          isCollapsed={isCollapsed}
          onSidebarClick={handleSidebarClick}
        />
      ) : (
        <SideNavBar
          isCollapsed={isCollapsed}
          onSidebarClick={handleSidebarClick}
        />
      )}
      {isFetchingUser ? (
        <FlexCentered height="100%" width="100%">
          <Spin />
        </FlexCentered>
      ) : (
        conditionalMainScreen
      )}

      <FOB />
    </StyledLayout>
  );
};
