import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Checkmark from '../../icons/Checkmark';
import { PrimaryWrapperBox } from '../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { FlexRow } from '../../styled/flex/FlexRow';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { colors, theme } from '../../theme';
import { ITagStatus } from '../../types/tag.types';
import { AssetAlarmsAndWarningsTable } from './AssetAlarmsAndWarningsTable';

const MESSAGES = defineMessages({
  heading: {
    id: 'box_warnings_and_errors.heading',
    defaultMessage: 'Errors and warnings',
  },
  noErrorsOrWarnings: {
    id: 'box_warnings_and_errors.noErrorsOrWarnings',
    defaultMessage: 'None',
  },
  subHeading: {
    id: 'box_warnings_and_errors.subHeading',
    defaultMessage: 'Actual',
  },
});

interface IProps {
  alarmsAndWarnings?: ITagStatus[];
}

export const AssetAlarmsAndWarnings = ({ alarmsAndWarnings }: IProps) => {
  const intl = useIntl();

  return (
    <>
      <StyledHeading variant="h4" my={theme.spacing.xLarge}>
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      <StyledHeading variant="h5" my={theme.spacing.xLarge}>
        {intl.formatMessage(MESSAGES.subHeading)}
      </StyledHeading>
      <PrimaryWrapperBox width="100%" bg={colors.white.primary} p={theme.spacing.xLarge}>
        {alarmsAndWarnings && alarmsAndWarnings.length > 0 ? (
          <AssetAlarmsAndWarningsTable alarmsAndWarnings={alarmsAndWarnings} />
        ) : (
          <FlexRow alignItems="center">
            <Checkmark height="24px" fill="none" width="24px" />
            <StyledBox ml={theme.spacing.medium}>
              {intl.formatMessage(MESSAGES.noErrorsOrWarnings)}
            </StyledBox>
          </FlexRow>
        )}
      </PrimaryWrapperBox>
    </>
  );
};
