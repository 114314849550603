import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { MemoizedConnectorAlarmsAndWarnings } from '../../../../../components/connectors/ConnectorAlarmsAndWarnings';
import { MemoizedConnectorSessionDetail } from '../../../../../components/connectors/ConnectorSessionDetail';
import { ConnectorStatusComponentsList } from '../../../../../components/connectors/ConnectorStatusComponentsList';
import { appUrls } from '../../../../../config/url.constants';
import { UNIT_MESSAGES } from '../../../../../consts/unit.const';
import { enabledConnectorsSelector } from '../../../../../store/selectors/connector.selectors';
import { PrimaryWrapperBox } from '../../../../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../../../styled/headings/StyledHeading';
import { StyledTable } from '../../../../../styled/table/StyledTable';
import { StyledLink } from '../../../../../styled/text/StyledLink';
import { StyledSpan } from '../../../../../styled/text/StyledSpan';
import { theme } from '../../../../../theme';
import { IConnectorBox } from '../../../../../types/connector.types';
import {
  IActiveSession,
  ISessionConsumption,
} from '../../../../../types/session.types';
import { ConnectorActionsList } from './ConnectorActionsList/ConnectorActionsList';
import { ConnectorStatusMessage } from './ConnectorStatusMessage/ConnectorStatusMessage';
import { RootState } from '../../../../../store/store';
import { ConnectorTag } from '../../../../../types/tag.types';
import { NoConnectionBox } from '../../../../../utils/boxes/components/NoConnectionBox';
import { formatNumber } from '../../../../../utils/shared/formats/formatNumbers';
import { TransposedTable } from '../../../../../components/tables/TransposedTable';

const MESSAGES = defineMessages({
  connector: {
    id: 'connectors_table.connector',
    defaultMessage: 'Connector',
  },
  connectorActions: {
    id: 'connectors_table.connectorActions',
    defaultMessage: 'Actions',
  },
  eni: {
    id: 'connectors_table.eni',
    defaultMessage: 'E.N.I. number',
  },
  noActiveSession: {
    id: 'connectors_table.noActiveSession',
    defaultMessage: 'No active session',
  },
  session: {
    id: 'connectors_table.session',
    defaultMessage: 'Session',
  },
  vesselName: {
    id: 'connectors_table.vesselName',
    defaultMessage: 'Vessel',
  },
  consumption: {
    id: 'sessions_info_table.usage',
    defaultMessage: 'Usage',
  },
  status: {
    id: 'sessions_info_table.status',
    defaultMessage: 'Status',
  },
  transactionId: {
    id: 'sessions_info_table.transactionId',
    defaultMessage: 'Transaction ID',
  },
});

const Container = styled.div`
  position: relative;
`;

const isMobile = window.innerWidth < 1024;
const TableContainer = styled.div<{ boxIsOffline: boolean }>`
  ${({ boxIsOffline }) =>
    boxIsOffline
      ? `
    opacity: .3;
    pointer-events: none;
    cursor: not-allowed;
    z-index: 0;
    filter: blur(1px);
  `
      : 'position:relative;'};
`;

const NoConnectionBoxContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  boxId: string;
  boxIsOffline?: boolean;
}

const updateTagStatuses = (tagStatusesArray: any, connectorStatuses: any) =>
  tagStatusesArray.map(({ id, tagStatuses }: any) => ({
    id,
    tagStatuses: Array.from(
      new Map(
        [...tagStatuses, ...(connectorStatuses[id] || [])].map((tag: any) => [
          tag.tag,
          tag,
        ]),
      ).values(),
    ),
  }));

export const ConnectorsTable = ({ boxId, boxIsOffline = false }: IProps) => {
  const intl = useIntl();

  const connectorStatuses = useSelector(
    (state: RootState) => state.tags.statuses,
  );
  const enabledConnectors = useSelector(enabledConnectorsSelector(boxId));

  const connectors: any = enabledConnectors?.map((connector) => {
    return {
      ...connector,
      tagStatuses: connectorStatuses[connector.id] || connector.tagStatuses,
    };
  });

  const updatedConnectors = updateTagStatuses(
    enabledConnectors,
    connectorStatuses,
  );

  const columns = [
    {
      title: intl.formatMessage(MESSAGES.connector),
      key: 'connector',
      // poor TS support of Ant Design on this prop called `fixed` hence typed as never to avoid error
      fixed: 'left' as never,
      width: 200,
      className: 'noPadding',
      render: (connector: IConnectorBox) => {
        const thisConnector = enabledConnectors?.find(
          (c) => c.id === connector.id,
        );

        const connectorWebStatusValue = connector?.tagStatuses.find(
          (status) => status?.tag === ConnectorTag.V1_WEB_STATUS,
        )?.value;
        const connectorSymbolStartValue = thisConnector?.tagStatuses.find(
          (status) => status?.tag === ConnectorTag.V2_SYMBOL_START,
        )?.value;

        return (
          <ConnectorStatusComponentsList
            boxId={boxId}
            connector={updatedConnectors.find(
              (c: any) => c.id === connector.id,
            )}
            connectorId={connector.id}
            connectorIndex={connector.index}
            connectorWebStatusValue={connectorWebStatusValue}
            connectorSymbolStartValue={connectorSymbolStartValue}
          />
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.eni),
      dataIndex: ['activeSession', 'vesselEniNumber'],
      key: 'eni',
      width: 100,
    },
    {
      title: intl.formatMessage(MESSAGES.vesselName),
      dataIndex: ['activeSession', 'vesselName'],
      key: 'vesselName',
      width: 100,
    },
    {
      title: intl.formatMessage(MESSAGES.consumption),
      dataIndex: ['activeSession', 'consumption'],
      key: 'consumption',
      render: (consumption: ISessionConsumption) => {
        return consumption
          ? `${formatNumber(consumption.total)} (${intl.formatMessage(
              UNIT_MESSAGES[consumption.unit],
            )})`
          : '';
      },
      width: 130,
    },
    {
      title: intl.formatMessage(MESSAGES.status),
      dataIndex: 'id',
      key: 'status',
      render: (id: string, connector: any) => (
        <ConnectorStatusMessage
          boxId={boxId}
          connectorId={id}
          connector={connector.tagStatuses}
        />
      ),
      width: 180,
    },
    {
      title: intl.formatMessage(MESSAGES.transactionId),
      dataIndex: 'activeSession',
      key: 'transactionId',
      render: (activeSession: IActiveSession) => {
        return (
          <>
            {activeSession && (
              <StyledLink
                to={appUrls.sessions.detail(activeSession.id)}
                variant="secondary"
              >
                {activeSession.transactionId}
              </StyledLink>
            )}
          </>
        );
      },
      width: 110,
    },
    {
      title: intl.formatMessage(MESSAGES.connectorActions),
      key: 'connectorActions',
      // poor TS support of Ant Design on this prop called `fixed` hence typed as never to avoid error
      fixed: 'right' as never,
      render: (connector: IConnectorBox) => {
        return (
          <ConnectorActionsList
            boxId={boxId}
            connectorTagStatuses={connector.tagStatuses}
            connectorId={connector.id}
          />
        );
      },
      width: 200,
    },
  ];

  return (
    <Container>
      {boxIsOffline && (
        <NoConnectionBoxContainer>
          <NoConnectionBox center />
        </NoConnectionBoxContainer>
      )}
      <TableContainer boxIsOffline={boxIsOffline}>
        {isMobile ? (
          <TransposedTable
            instance="connectors"
            columns={columns}
            data={connectors}
          />
        ) : (
          <StyledTable
            columns={columns}
            dataSource={connectors}
            expandable={{
              columnWidth: '34px',
              // lint error can be suppressed because the component is memoized to avoid unstable rendering
              // eslint-disable-next-line react/no-unstable-nested-components
              expandedRowRender: (record: IConnectorBox) => (
                <>
                  <MemoizedConnectorAlarmsAndWarnings
                    boxId={boxId}
                    connectorId={record.id}
                  />
                  {record.activeSession ? (
                    <StyledBox mt={theme.spacing.medium}>
                      <MemoizedConnectorSessionDetail
                        sessionId={record.activeSession.id}
                      />
                    </StyledBox>
                  ) : (
                    <>
                      <StyledHeading variant="h4" my={theme.spacing.xLarge}>
                        {intl.formatMessage(MESSAGES.session)}
                      </StyledHeading>
                      <PrimaryWrapperBox width="fit-content">
                        <StyledSpan>
                          {intl.formatMessage(MESSAGES.noActiveSession)}
                        </StyledSpan>
                      </PrimaryWrapperBox>
                    </>
                  )}
                </>
              ),
            }}
            pagination={false}
            rowKey={(record: IConnectorBox) => record.id}
            scroll={{ x: 1200 }}
          />
        )}
      </TableContainer>
    </Container>
  );
};
