import Auth from '@aws-amplify/auth';
import { Client } from '@stomp/stompjs';

import ENV from '../shared/environment';
import { storeAccessTokenAndEmailLocalStorage } from '../api/localStorage.utils';

export const BROKER_DESTINATION_PREFIX = "/topic";

class WsClient {
  client: Client;
  connected: Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolveConnected: any;

  constructor() {
    this.connected = new Promise((resolve) => {
      this.resolveConnected = resolve;
    });
    this.client = new Client();
  }

  init(token: string) {
    this.client.configure({
      brokerURL: ENV.REACT_APP_WS_URL,
      connectHeaders: {
        /* Should be fine to set here once because the token is only validated on CONNECT.
         * Auth error only when connection breaks & reconnects without user interaction after token has expired, acceptable for now. */
        Authorization: `Bearer ${token}`,
      },
      onStompError: (frame) => {
        if (frame.headers?.message?.includes('Jwt expired')) {
          this.handleTokenExpiration();
        } else {
          // Stop further connection attempts on other errors
          this.stopConnection();
        }
      },
      onConnect: () => {
        this.resolveConnected();
      },
    });
    this.client.activate();
  }

  private async handleTokenExpiration() {
    // console.error(
    //   'Token expired. Please refresh the token and try reconnecting.',
    // );

    try {
      const session = await Auth.currentSession();
      let tokenRefreshed = session.getIdToken().getJwtToken();

      await Auth.currentSession().then(async (sess) => {
        tokenRefreshed = sess.getIdToken().getJwtToken();
        storeAccessTokenAndEmailLocalStorage(tokenRefreshed);
        // retry the initial query with a new token in state
        await this.reconnect(tokenRefreshed);
      });
    } catch (error) {
      // console.error('Failed to refresh token:', error);
      this.stopConnection();
    }
  }

  private reconnect(token: string) {
    this.client.deactivate(); // Deactivate current client
    this.client = new Client(); // Create a new client instance
    this.init(token); // Initialize with the new token
  }

  private stopConnection() {
    // console.log('Stopping WebSocket connection attempts due to error.');
    // Clean up resources or notify users if necessary
    this.client.deactivate();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async subscribe(topic: string, callback: (payload: any) => void) {
    await this.connected; // subscribing before the client is connected leads to an error
    return this.client.subscribe(topic, (message) => {
      const payload = JSON.parse(message.body);
      callback(payload);
    });
  }
}

export const wsClient = new WsClient(); // export an instance, we should always reuse a single connection
