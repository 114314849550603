export enum BoxDetailTabs {
  STATUS = 'STATUS',
  SESSIONS = 'SESSIONS',
  DEVELOPER = 'DEVELOPER',
  LOGS = 'LOGS',
}

export enum TaskTabs {
  EDIT = 'EDIT',
  LOGS = 'LOGS',
}