export const appUrls = {
  base: '/',
  login: {
    base: '/login',
    resetPassword: '/reset-password',
    resetPasswordCode: '/reset-password-code',
    resetPasswordSet: (code = ':code') => `/reset-password-set/${code}`,
    register: '/register',
    confirmEmail: '/confirm-email/:username/:code',
    landingAfterLogin: '/landing',
  },
  dashboard: {
    base: '/dashboard',
  },
  assets: {
    base: '/assets',
    command: (assetId = ':assetId') => `/assets/${assetId}/actions`,
    statusPortal: (assetId = ':assetId') => `/assets/${assetId}/status`,
    devPortal: (assetId = ':assetId') => `/assets/${assetId}/dev`,
    sessionsPortal: (assetId = ':assetId') => `/assets/${assetId}/sessions`,
    logsPortal: (assetId = ':assetId') => `/assets/${assetId}/logs`,
  },
  connectors: {
    base: '/connectors',
    detail: (connectorId = ':connectorId') => `/assets/${connectorId}/config`,
  },
  sessions: {
    base: '/sessions',
    detail: (sessionId = ':sessionId') => `/sessions/${sessionId}`,
  },
  users: {
    base: '/users',
    edit: (id = ':id') => `/users/edit/${id}`,
    new: '/users/new',
  },
  vessels: {
    base: '/vessels',
    edit: (vesselId = ':vesselId') => `/vessels/edit/${vesselId}`,
    new: '/vessels/new',
  },
  maintenance: {
    base: '/maintenance',
    edit: (task = ':task') => `/maintenance/${task}/edit`,
    logs: (task = ':task') => `/maintenance/${task}/logs`
  },
  settings: {
    base: '/settings',
    profile: '/settings/profile',
  },
};
