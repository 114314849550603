import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { StompSubscription } from '@stomp/stompjs';
import { RootState } from '../store/store';
import { BROKER_DESTINATION_PREFIX, wsClient } from '../utils/ws/WsClient';

interface IUseSubscriptionByIdParams {
  entityId: string;
  path: string;
  onMessage: (message: any) => void;
  initAction?: () => AnyAction;
  endAction?: () => AnyAction;
  selectSubscribedIds?: (state: RootState) => string[];
}

export const useSubscribeById = ({
  entityId,
  path,
  onMessage,
  initAction,
  endAction,
  selectSubscribedIds,
}: IUseSubscriptionByIdParams) => {
  const dispatch = useDispatch();
  const subscribedIds = useSelector(selectSubscribedIds || (() => []));

  useEffect(() => {
    if (!entityId) return undefined;

    if (subscribedIds.includes(entityId)) return undefined;

    let subscription: StompSubscription | null = null;

    const subscribe = async () => {
      subscription = await wsClient.subscribe(
        `${BROKER_DESTINATION_PREFIX}/${path}`,
        onMessage,
      );

      if (initAction) {
        dispatch(initAction());
      }
    };

    subscribe();

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
      if (endAction) {
        dispatch(endAction());
      }
    };
  }, [entityId, dispatch]);
};
